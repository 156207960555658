<template>
  <span class="label px-2 py-0.5 rounded-md" :class="classes">
    {{ currency }}
  </span>
</template>

<script setup>
import { getCurrencyBadgeStyle } from '@/utils/getCurrencyBadgeStyle';

const props = defineProps({
  currency: {
    type: String,
    required: true,
  },
});

const classes = computed(() => getCurrencyBadgeStyle(props.currency));
</script>
