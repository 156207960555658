<template>
  <div v-if="breakdown.length > 1" class="mt-2 text-xs">
    <div
      v-for="(item, index) in breakdown"
      :key="index"
      class="p-3 first:rounded-t-xl last:rounded-b-xl bg-slate-800 border-b border-b-slate-300 last:border-b-0 flex justify-between"
    >
      <div class="flex flex-col">
        <span class="font-bold">{{ item.name }}</span>
        <span class="text-gray-400 mr-3 font-bold text-2xs mt-1">{{ item.description }}</span>
        <span v-if="item.note" class="text-orange-500 mr-3 text-3xs mt-2 font-bold">
          <span class="px-1 rounded-sm text-4xs bg-orange-500 text-cyan-900 mr-0.5">Note</span>
          {{ item.note }}
        </span>
      </div>
      <span class="font-bold min-w-[80px] text-right">
        {{ formatValue(item.value) }}
      </span>
    </div>
  </div>
</template>

<script setup>
const { $truncateNumber, } = useNuxtApp();

const props = defineProps({
  balance: {
    type: Object,
    default: null,
  },
  maxDecimals: {
    type: Number,
    default: 6,
  },
});

function formatValue(value) {
  return $truncateNumber(value, props.maxDecimals, true);
}

function mapName(name) {
  switch (name) {
    case 'deposit':
      return 'Deposit';

    case 'withdrawable':
      return props.balance.virtual ? 'Redeemable' : 'Withdrawable';

    case 'depositCustodial':
      return 'Deposit';

    case 'custodial':
      return props.balance.virtual ? 'Redeemable' : 'Withdrawable';

    default:
      return _Capitalize(name);
  }
}

function mapDescription(name) {
  switch (name) {
    case 'playable':
      return 'Can be used for playing games and WinBoxes.';

    case 'deposit':
    case 'depositCustodial':
      return 'Can be played but not withdrawn, any winnings will go to your Withdrawable balance.';

    case 'withdrawable':
      return props.balance.virtual ? 'Can be redeemed for ETH.' : 'Can be played or withdrawn.';

    case 'custodial':
      return 'Can be be played or withdrawn.';
  }
}

function mapNote(name) {
  switch (name) {
    case 'playable':
      return 'Playable funds are shared between Web3 and Custodial balances of the same currency.';
  }
}

const breakdown = computed(() => {
  if (!props.balance) { return []; }

  return props.balance.breakdown.map(b => ({
    name: mapName(b.name),
    description: mapDescription(b.name),
    note: mapNote(b.name),
    value: b.value,
  }));
});
</script>
